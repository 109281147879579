import React from "react";
import styled from "styled-components";
import { BaseTopHeading } from "./header";
import { WideContainer } from "./container";
import { StaticQuery, graphql, Link, useStaticQuery } from "gatsby";
import { HtmlContent } from "./html-content";
import BackgroundImage from "gatsby-background-image";
import Img, { FixedObject, FluidObject } from "gatsby-image";
import { OutlineButtonInner } from "./button";
import { HeroQuery } from "../../graphql-types";

/**
 * Hero
 */

export const Hero: React.FC = () => {
  const data = useStaticQuery<HeroQuery>(graphql`
    query Hero {
      wpPage(uri: { eq: "/" }) {
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }

        homeCustomFields {
          tagline
          introImage {
            localFile {
              childImageSharp {
                fixed(width: 500, height: 550) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <BackgroundImage
        fluid={[
          "linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))",
          data.wpPage?.featuredImage?.node?.localFile?.childImageSharp
            ?.fluid as FluidObject,
        ]}
        fadeIn={false}
      >
        <HeaderWrapper>
          <WideContainer style={{ position: "relative", top: "30%" }}>
            <Tagline>{data.wpPage?.homeCustomFields?.tagline}</Tagline>
          </WideContainer>
        </HeaderWrapper>
      </BackgroundImage>

      <IntroContainer>
        <IntroContents>
          <IntroMain>
            <HtmlContent
              dangerouslySetInnerHTML={{ __html: data.wpPage?.content || "" }}
            />
          </IntroMain>
          <IntroImageWrapper>
            <Img
              fixed={
                data.wpPage?.homeCustomFields?.introImage?.localFile
                  ?.childImageSharp?.fixed as FixedObject
              }
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          </IntroImageWrapper>
        </IntroContents>

        <div style={{ marginTop: 30, marginBottom: 60 }}>
          <Link to="/over-ons/">
            <OutlineButtonInner>Over Deco Art &rarr;</OutlineButtonInner>
          </Link>
        </div>
      </IntroContainer>
    </>
  );
};

/**
 * Header wrapper
 */

const HeaderWrapper = styled.div`
  height: 75vh;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 60vh;
  }
`;

/**
 * Tagline
 */

const Tagline = styled(BaseTopHeading)`
  font-size: 40px;
  font-weight: 100;
  max-width: 10em;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 60px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 90px;
  }
`;

const IntroContainer = styled(WideContainer)`
  padding-bottom: 2.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-bottom: 4rem;
  }
`;

/**
 * Intro contents
 */

const IntroContents = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

/**
 * Intro main
 */

const IntroMain = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 65%;
    align-self: flex-end;
    margin-top: 10%;
  }
`;

/**
 * Intro image wrapper
 */

const IntroImageWrapper = styled.div`
  align-self: flex-end;
  width: 65%;
  height: 80vw;
  margin-top: -35%;
  margin-bottom: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: auto;
    align-self: flex-start;
    text-align: right;
    width: 30%;
    margin-top: -10%;
  }
`;
