import React from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import { WideContainer, NarrowContainer } from "./container";
import { chunk } from "lodash";
import Img, { FluidObject } from "gatsby-image";
import { OutlineButtonInner } from "./button";
import { intersection } from "lodash";
import { ProjectsQuery } from "../../graphql-types";

type ProjectsProps = {
  title?: string;
  content?: string;
  filterByServices?: string[];
  containerWidth?: "narrow" | "wide";
};

export const Projects: React.FC<ProjectsProps> = ({
  title,
  content,
  filterByServices,
  containerWidth,
}) => {
  const Container =
    containerWidth === "narrow" ? NarrowContainer : WideContainer;

  const data = useStaticQuery<ProjectsQuery>(graphql`
    query Projects {
      allWpProject {
        edges {
          node {
            uri
            title
            projectCustomFields {
              services {
                ... on WpService {
                  title
                }
              }
              images {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const projects = data.allWpProject.edges.filter(function (edge) {
    return filterByServices
      ? intersection(
          edge.node.projectCustomFields?.services?.map(
            (service) => service?.title
          ) || [],
          filterByServices
        ).length !== 0
      : true;
  });

  if (!projects.length) return null;

  return (
    <div style={{ marginTop: 50 }}>
      <Container>
        <Title>{title ? title : "Projecten"}</Title>
        <div style={{ marginBottom: 20 }}>
          {content
            ? content
            : "Krijg een indruk van onze kwaliteiten in belettering door een blik te werpen op enkele gerealiseerde projecten."}
        </div>
      </Container>

      <ProjectSequences>
        {chunk(projects, 4).map((projectSequence: typeof projects, i) => {
          const SequenceComponent = !(i % 2)
            ? ProjectSequence
            : ProjectSequenceReversed;

          return (
            <SequenceComponent
              style={{
                height: "60vh",
                width: "90vh",
                minWidth: 600,
                minHeight: 400,
              }}
              key={i}
            >
              {projectSequence.map((project, ii) => (
                <Project key={ii}>
                  <Link to={project.node.uri}>
                    {project.node.projectCustomFields?.images?.[0] && (
                      <Img
                        fluid={
                          project.node.projectCustomFields?.images?.[0]
                            ?.localFile?.childImageSharp?.fluid as FluidObject
                        }
                        style={{ height: "100%", width: "100%" }}
                      />
                    )}
                    <ProjectTitle>{project.node.title}</ProjectTitle>
                  </Link>
                </Project>
              ))}
            </SequenceComponent>
          );
        })}
      </ProjectSequences>

      <div style={{ display: "flex", justifyContent: "center", padding: 40 }}>
        <Link to={"/projecten/"}>
          <OutlineButtonInner>Alle projecten &rarr;</OutlineButtonInner>
        </Link>
      </div>
    </div>
  );
};

const Title = styled.h2`
  font-family: Poppins, sans-serif;
  color: #0b0b0b;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 100;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 35px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 40px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 50px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 60px;
  }
`;

const ProjectTitle = styled.div`
  position: absolute;
  width: calc(100% - 20px);
  bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 15px;
  text-align: center;
  background-color: #00a88e;
  color: #fff;

  & ::after {
    content: "→";
    position: absolute;
    right: 15px;
    bottom: 5px;
    font-size: 14px;
  }
`;

export const Project = styled.div`
  position: relative;
  overflow: hidden;
  float: left;
  padding: 10px;

  & ${ProjectTitle} {
    opacity: 0;
    transition: opacity 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  & :hover ${ProjectTitle} {
    opacity: 1;
  }
`;

export const ProjectSequence = styled.div`
  & ${Project}:nth-child(1) {
    height: 100%;
    width: 33.333%;
  }

  & ${Project}:nth-child(2) {
    width: 66.666%;
    height: 50%;
  }

  & ${Project}:nth-child(3) {
    width: 33.333%;
    height: 50%;
  }

  & ${Project}:nth-child(4) {
    width: 33.333%;
    height: 50%;
  }

  &::after {
    content: "";
    clear: both;
    display: table;
  }
`;

export const ProjectSequenceReversed = styled(ProjectSequence)`
  ${Project}:nth-child(1) {
    height: 50%;
    width: 66.666%;
  }

  ${Project}:nth-child(2) {
    float: right;
    width: 33.333%;
    height: 100%;
  }

  & ${Project}:nth-child(3) {
    width: 33.333%;
    height: 50%;
  }

  & ${Project}:nth-child(4) {
    width: 33.333%;
    height: 50%;
  }
`;

const ProjectSequences = styled.div`
  width: calc(100vw + 10px);
  display: flex;
  overflow-y: scroll;
  margin-left: 5px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: -10px;
  }

  & ${ProjectSequence} {
    flex-shrink: 0;
  }
`;
