import React from "react";
import styled from "styled-components";
import { WideContainer } from "./container";
import Helmet from "react-helmet";
import Swiper from "react-id-swiper";
import { graphql, Link, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { ServicesQuery } from "../../graphql-types";

export const Services: React.FC = () => {
  const data = useStaticQuery<ServicesQuery>(graphql`
    query Services {
      allWpService {
        edges {
          node {
            uri
            title
            serviceCustomFields {
              subtitle
            }
          }
        }
      }

      wp {
        globaal {
          globalCustomFields {
            servicesBlockBackground {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const [swiper, updateSwiper] = React.useState(null as any);
  const swiperParams = {
    slidesPerView: "auto",
    spaceBetween: 18,
  };

  const prevSlide = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const nextSlide = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css"
        />
      </Helmet>

      <BackgroundImage
        fluid={[
          "linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))",
          data.wp?.globaal?.globalCustomFields?.servicesBlockBackground
            ?.localFile?.childImageSharp?.fluid,
        ]}
        fadeIn={false}
      >
        <ServicesWrapper>
          <WideContainer
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "2rem",
            }}
          >
            <BlockTitle>Onze diensten</BlockTitle>
            <div style={{ color: "#fff", flexShrink: 0 }}>
              <CircularButton
                style={{ marginRight: "1rem" }}
                onClick={prevSlide}
              >
                &larr;
              </CircularButton>
              <CircularButton onClick={nextSlide}>&rarr;</CircularButton>
            </div>
          </WideContainer>

          <SwiperWrapper>
            <Swiper getSwiper={updateSwiper} {...swiperParams}>
              {data.allWpService.edges.map((edge, i) => (
                <ServiceItem key={i}>
                  <Link to={edge.node.uri}>
                    <ServiceItemInner>
                      <div>
                        <Title>{edge.node.title}</Title>
                        <SubTitle>
                          {edge.node.serviceCustomFields?.subtitle}
                        </SubTitle>
                      </div>
                    </ServiceItemInner>
                  </Link>
                </ServiceItem>
              ))}
            </Swiper>
          </SwiperWrapper>
        </ServicesWrapper>
      </BackgroundImage>
    </>
  );
};

const ServicesWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 80px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-bottom: 100px;
    padding-top: 0;
  }
`;

const BlockTitle = styled.h2`
  font-family: Poppins, sans-serif;
  font-weight: 100;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 35px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 40px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 50px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: 60px;
  }

  color: #fff;
`;

const SwiperWrapper = styled.div`
  .swiper-container {
    padding: 0 18px;
  }
`;

const Title = styled.div`
  color: #0b0b0b;
  font-size: 19px;
  text-transform: uppercase;
  font-weight: 700;
`;

const SubTitle = styled.div`
  font-size: 16px;
`;

const ServiceItem = styled.div`
  width: 90vw;
  max-width: 280px;
`;

const ServiceItemInner = styled.div`
  height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
  padding: 15px;

  ::after {
    content: "→";
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
    color: #00a88e;
    font-weight: 700;
  }

  :hover {
    background-color: #00a88e;

    ${Title}, ${SubTitle} {
      color: #fff;
    }

    ::after {
      color: #fff;
    }
  }
`;

const CircularButton = styled.button`
  background-color: #00a88e;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: none;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  font-weight: 700;

  :hover {
    background-color: #137264;
  }
`;
