import React from "react";
import SEO from "../components/seo";
import { Layout } from "../components/layout";
import { Metrics } from "../components/metrics";
import { Hero } from "../components/hero";
import { Services } from "../components/services";
import { Projects } from "../components/projects";

const IndexPage = () => (
  <Layout>
    <SEO title="Belettering" />

    <Hero />

    <Metrics />

    <Services />

    <Projects />
  </Layout>
);

export default IndexPage;
